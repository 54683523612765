import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './assets/common/store';
import tool from './assets/common/tool';

import { Button, Image, Field, NavBar, Circle, Switch, Popup, ActionSheet, Checkbox, Tab, Tabs, Uploader, RadioGroup, Radio, DatetimePicker, Progress, Calendar, Loading, Overlay, PullRefresh, List, Dialog, Toast } from 'vant';
Vue.use(Button);
Vue.use(Image);
Vue.use(Button);
Vue.use(Field);
Vue.use(NavBar);
Vue.use(Circle);
Vue.use(Switch);
Vue.use(Popup);
Vue.use(ActionSheet);
Vue.use(Checkbox);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Uploader);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(DatetimePicker);
Vue.use(Progress);
Vue.use(Calendar);
Vue.use(Loading);
Vue.use(Overlay);
Vue.use(PullRefresh);
Vue.use(List);

Vue.config.productionTip = false;//关闭生产模式下给出的提示
Vue.prototype.$tool = tool;

import 'amfe-flexible';
/*import 'lib-flexible/flexible';//rem.js文件代码
// 基准大小
const baseSize = 16
// 设置 rem 函数
function setRem () {
  // 当前页面宽度相对于 750 宽的缩放比例，可根据自己需要修改。
  const scale = document.documentElement.clientWidth / 375
  console.log(scale)
  // 设置页面根节点字体大小
  document.documentElement.style.fontSize = (baseSize * Math.min(scale, 2)) + 'px'
  console.log(document.documentElement.style.fontSize)
}
// 初始化
setRem()
// 改变窗口大小时重新设置 rem
window.onresize = function () {
  setRem()
}*/

new Vue({
  router,
  Toast,
  Dialog,
  store,
  render: h => h(App),
}).$mount('#app')