//公共工具方法定义.js
import { Toast } from 'vant';
import router from '@/router';
import store from '@/assets/common/store'
import {
    activityDetail,
    listActivityOrganizers
} from "@/api/index";
export default {
    //接口响应验证处理（所有接口都需调用该方法进行验证）
    resValidation(data,callBack,errorBack){
        if(data.code === 0){
            if(typeof callBack == "function") callBack();
        }else if(data.code === 109){
            Toast.fail('无登录信息或登录信息失效，请重新登录');
            router.push('login');
        }else{
            Toast.fail(data.msg);
            if(typeof errorBack == "function") errorBack();
        }
    },
    //字符串链接 urlEncode 转译
    toURLEncode(str){
        str = (str + '').toString();
        return encodeURIComponent(str).replace(/!/g, '%21').replace(/'/g, '%27').replace(/\(/g, '%28').
        replace(/\)/g, '%29').replace(/\*/g, '%2A').replace(/%20/g, '+');
    },
    //获取当前登录活动对象详情
    getActObjInfo(id,callBack,errorBack){
        let params = { activityId: id };
        activityDetail(params).then(res => {
            this.resValidation(res, () => {
                store.commit('setOPDetails', res.data);
                store.commit('setActObjId', res.data.id);
                store.commit('setCompanyId', res.data.actOther.relateId);
                store.commit('setActivityId', res.data.activityId);
                if(typeof callBack == "function") callBack(res.data);
            }, () => {
                if(typeof errorBack == "function") errorBack();
            })
        },err => {})
    },
    //修改对象下各负责人活动状态 relateType：人员类型；status：签到签退状态
    updateSOPDSetsStatus(relateType,status){
        let activitySets = store.getters.getSelectOPDetails.activitySets;
        for(let i in activitySets){
            if(activitySets[i].relateType === relateType){
                activitySets[i].statusSendId = status;
            }
        }
        store.getters.getSelectOPDetails.activitySets = activitySets;
        store.commit("setSelectOPDetails", store.getters.getSelectOPDetails);
    },
    //日期格式化
    dateFormat(date){
        return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).substr(-2)}-${('0' + date.getDate()).substr(-2)}`;
    },
    //日期时间格式化
    dateTimeFormat(date){
        return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).substr(-2)}-${('0' + date.getDate()).substr(-2)} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    },
    //身份验证 status：0-人员对接负责人 1-车辆对接负责人 2-住宿安排负责人 3-用餐安排负责人 4-物资发放负责人 5-车辆服务负责人 6-活动助理 7-现场总指挥
    identityValidation(id, status){
        let list = store.getters.getJobList;
        let isIdentity = false;
        list.forEach(item => {
            if(item.relateType === status){
                if(item.activityId === id){
                    isIdentity = true;
                }
            }
        })
        return isIdentity;
    },
    //数组唯一性处理 1、arr 数组；2、key 对比数组项中的某个属性，不传时对比整个数组项
    arrayUnique(arr,key) {
        for (let i = 0, len = arr.length; i < len; i++) {
            for (let j = i + 1, len = arr.length; j < len; j++) {
                if (key ? (arr[i][key] === arr[j][key]) : (arr[i] === arr[j])) {
                    arr.splice(j, 1);
                    j--;
                    len--;
                }
            }
        }
        return arr;
        /*let hash = {};
        return arr.reduce(function (item, next) {
            hash[next.name] ? '' : hash[next.name] = true && item.push(next);
            return item;
        }, []);*/
    }
}