<template>
  <div>
    <!-- 路由对应组件的显示 -->
    <router-view></router-view>
  </div>
</template>


<script>

export default {
  name: 'App',
  methods: {

  },
}
</script>

<style lang="scss">
body{
  background-color: #F2F5FC;
}
.van-toast {
  position: fixed;
  top: 50%;
  left: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  box-sizing: content-box;
  width: 160px;
  max-width: 70%;
  padding: 16px;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  white-space: pre-wrap;
  text-align: center;
  word-break: break-all;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}
.loading-box{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>