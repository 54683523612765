import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

let router = new Router({
    routes: [
        {
            path: '*',
            name: '*',
            component: () => import('@/components/NotFound')
        },
        {
            path: '/',
            redirect: '/login'
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/components/Login')
        },
        {
            path: '/parIndex',
            name: 'parIndex',
            component: () => import('@/components/parModule/parIndex')
        },
        {
            path: '/parCheck',
            name: 'parCheck',
            component: () => import('@/components/parModule/parCheck')
        },
        {
            path: '/parMaterial',
            name: 'parMaterial',
            component: () => import('@/components/parModule/parMaterial')
        },
        {
            path: '/parMaterial_details',
            name: 'parMaterial_details',
            component: () => import('@/components/parModule/parMaterial_details')
        },
        {
            path: '/parMeals',
            name: 'parMeals',
            component: () => import('@/components/parModule/parMeals')
        },
        {
            path: '/parMeals_address',
            name: 'parMeals_address',
            component: () => import('@/components/parModule/parMeals_address')
        },
        {
            path: '/parMeals_send_code',
            name: 'parMeals_send_code',
            component: () => import('@/components/parModule/parMeals_send_code')
        },
        {
            path: '/parMeals_room_code',
            name: 'parMeals_room_code',
            component: () => import('@/components/parModule/parMeals_room_code')
        },
        {
            path: '/parLive',
            name: 'parLive',
            component: () => import('@/components/parModule/parLive')
        },
        {
            path: '/parLive_details',
            name: 'parLive_details',
            component: () => import('@/components/parModule/parLive_details')
        },
        {
            path: '/parConsumption',
            name: 'parConsumption',
            component: () => import('@/components/parModule/parConsumption')
        },
        {
            path: '/parConsumption_cost',
            name: 'parConsumption_cost',
            component: () => import('@/components/parModule/parConsumption_cost')
        },
        {
            path: '/parClear',
            name: 'parClear',
            component: () => import('@/components/parModule/parClear')
        },
        {
            path: '/parClear_cost',
            name: 'parClear_cost',
            component: () => import('@/components/parModule/parClear_cost')
        },
        {
            path: '/par_success',
            name: 'par_success',
            component: () => import('@/components/parModule/par_success')
        },
        {
            path: '/orgIndex',
            name: 'orgIndex',
            component: () => import('@/components/orgModule/orgIndex')
        },
        {
            path: '/orgCheck',
            name: 'orgCheck',
            component: () => import('@/components/orgModule/orgCheck')
        },
        {
            path: '/orgCheck_details',
            name: 'orgCheck_details',
            component: () => import('@/components/orgModule/orgCheck_details')
        },
        {
            path: '/orgCheck_car',
            name: 'orgCheck_car',
            component: () => import('@/components/orgModule/orgCheck_car')
        },
        {
            path: '/orgCheck_person',
            name: 'orgCheck_person',
            component: () => import('@/components/orgModule/orgCheck_person')
        },
        {
            path: '/orgCheck_live',
            name: 'orgCheck_live',
            component: () => import('@/components/orgModule/orgCheck_live')
        },
        {
            path: '/orgCheck_meals',
            name: 'orgCheck_meals',
            component: () => import('@/components/orgModule/orgCheck_meals')
        },
        {
            path: '/orgCheck_material',
            name: 'orgCheck_material',
            component: () => import('@/components/orgModule/orgCheck_material')
        },
        {
            path: '/orgCheck_material_details',
            name: 'orgCheck_material_details',
            component: () => import('@/components/orgModule/orgCheck_material_details')
        },
        {
            path: '/orgMaterial',
            name: 'orgMaterial',
            component: () => import('@/components/orgModule/orgMaterial')
        },
        {
            path: '/orgMaterial_recycling',
            name: 'orgMaterial_recycling',
            component: () => import('@/components/orgModule/orgMaterial_recycling')
        },
        {
            path: '/orgMeals',
            name: 'orgMeals',
            component: () => import('@/components/orgModule/orgMeals'),
            meta: {
                keepAlive: true
            }
        },
        {
            path: '/orgMeals_query',
            name: 'orgMeals_query',
            component: () => import('@/components/orgModule/orgMeals_query')
        },
        {
            path: '/orgMeals_edit',
            name: 'orgMeals_edit',
            component: () => import('@/components/orgModule/orgMeals_edit')
        },
        {
            path: '/orgMeals_details',
            name: 'orgMeals_details',
            component: () => import('@/components/orgModule/orgMeals_details')
        },
        {
            path: '/orgMeals_details_add',
            name: 'orgMeals_details_add',
            component: () => import('@/components/orgModule/orgMeals_details_add')
        },
        {
            path: '/orgLive',
            name: 'orgLive',
            component: () => import('@/components/orgModule/orgLive')
        },
        {
            path: '/orgConsumption',
            name: 'orgConsumption',
            component: () => import('@/components/orgModule/orgConsumption')
        },
        {
            path: '/orgClear',
            name: 'orgClear',
            component: () => import('@/components/orgModule/orgClear')
        },
        {
            path: '/smsModule/activityOrganization',
            name: 'activityOrganization',
            component: () => import('@/components/smsModule/activityOrganization')
        },
        {
            path: '/smsModule/mealDeliveryNotice',
            name: 'mealDeliveryNotice',
            component: () => import('@/components/smsModule/mealDeliveryNotice')
        }
    ]
});

router.beforeEach((to, from, next) => {
    if(from.name === "par_success" && to.name !== "login"){
        next({
            path: '/login'
        })
    }else{
        next();
    }
});

export default router;