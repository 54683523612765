import * as http from './http';

// 活动组织人或参与人登录
export const userLogin = (data) => http.get('company/activityH5/login', data);
// 发送验证码

export const getCodeSms = (data) => http.get('sms/common/sms', data);
// 参与人首页
export const activityDetail = (data) => http.get('company/activity/unNeedToken/activityDetail', data);
// 组织人员首页
export const tissueFirstPage = (data) => http.get('company/activityH5/tissueFirstPage', data);


/*---------------------参与人登录后接口----------------------------*/
// 参与人用餐安排首页 餐食负责人信息或堂食餐厅信息：objInfo，用餐安排：foodArrange，派餐时派餐信息：sendMealInfo 有没有领过餐(派餐或堂食取餐) getMealInfo
export const personFoodArrange = (data) => http.get('company/activityH5/personFoodArrange', data);
// 参与人住宿安排首页
export const participantStay = (data) => http.get('company/activityH5/participantStay', data);
// 参与人办理车辆能耗、清洁、住宿登记、派餐时领取餐食、堂食时领取餐食
export const energyWashRoomFoodRegister = (data) => http.post('company/activityH5/unNeedToken/energyWashRoomFoodRegister', data);

/*---------------------组织者登录后接口----------------------------*/
// 本次活动所有参与人员列表(用餐管理时可选的用餐人员)
export const activityCanSelectParticipant = (data) => http.get('company/activityH5/activityCanSelectParticipant', data);
// 人员、车辆签到、签退首页
export const activityObjSignPage = (data) => http.get('company/activityH5/activityObjSignPage', data);
// 餐厅的餐食详情 actId、startDate、endDate、relateType、pageNum、pageSize、companyId、num必传，确定签收需前端请求编辑活动餐食接口
export const canteenFoodStatistics = (data) => http.post('company/activityH5/unNeedToken/canteenFoodStatistics', data);
// 选择用餐人员后的页面展示
export const foodObjShow = (data) => http.get('company/activityH5/foodObjShow', data);
// 用餐安排的相关统计 actId、startDate、endDate、relateType、companyId必传
export const foodPlanStatistics = (data) => http.post('company/activityH5/foodPlanStatistics', data);
// 物资发放，没有勾选的物资也需要传参，发放数量传0
export const grantMaterial = (data) => http.post('company/activityH5/unNeedToken/grantMaterial', data);
// 物资详情
export const h5MaterialDetail = (data) => http.get('company/activityH5/unNeedToken/h5MaterialDetail', data);
// 人员、车辆对接工作列表，参数:返回的活动对象。我的任务时，ayId需传参，全部任务时，不传参
export const personCarButt = (data) => http.get('company/activityH5/personCarButt', data);
//人员、车辆签到、签退，确认派餐
export const personCarSign = (data) => http.post('company/activityH5/personCarSign', data);
// 物资发放展示
export const showActivityMaterial = (data) => http.get('company/activityH5/unNeedToken/showActivityMaterial', data);
// 根据活动主键id获取所有组织者
export const listActivityOrganizers = (data) => http.get('company/activityH5/listActivityOrganizers', data);
//查询活动人员列表
export const canSelectStayFoodGrantPersons = (data) => http.post('company/activityH5/canSelectStayFoodGrantPersons', data);
//批量编辑物资、餐食、住宿等(需要传id)、餐厅详情时确认签收
export const editActivityMaterials = (data) => http.post('company/activityH5/editActivityMaterials', data);
//车辆能耗、车辆清洁可选的车辆列表
export const actCarObjPage = (data) => http.get('company/activityH5/actCarObjPage', data);
//组织人选择车辆后，车辆能耗、车辆清洁展示 carInfo-车辆基本信息 carUseInfo-车辆使用记录 consumeDate-消费周期
export const selectCarShow = (data) => http.get('company/activityH5/selectCarShow', data);
//组织人住宿安排界面展示
export const stayArrangeShow = (data) => http.get('/company/activityH5/stayArrangeShow', data);
//组织人通知入住或通知退房
export const informCheckInOut = (data) => http.post('/company/activityH5/informCheckInOut', data);
//获取活动对象人通知入住或通知退房相关信息
export const getActivityHotel = (data) => http.get('/company/activityH5/unNeedToken/getActivityHotel', data);

//公司已创建的组织活动或组织活动对象列表
export const activityList = (data) => http.post('company/activity/activityList', data);
// 新增活动对象时可选的酒店、房型
export const addActivityObjectCanSelectHotelRoom = (data) => http.post('company/activity/addActivityObjectCanSelectHotelRoom', data);
//接收新签到人员通知
export const addSetActivityOrActivityObject = (data) => http.post('company/activity/addSetActivityOrActivityObject', data);
//新增、编辑物资、餐食、住宿(新增时,不存在id，编辑时存在id)
export const addEditActivityMaterial = (data) => http.post('company/activity/addEditActivityMaterial', data);
//物资、餐食、住宿列表
export const activityMaterialList = (data) => http.post('company/activity/unNeedToken/activityMaterialList', data);

// 短信通知接口
export const noticeContent = (data) => http.post('company/activityH5/unNeedToken/noticeContent', data);

// 查询微信是否绑定了手机号(绑定了返回用户信息)
export const wechatWhetherBindPhone = (data) => http.get('vvorder/wxAuth/wechatWhetherBindPhone', data);
// 获取微信code
export const getWxCode = (data) => {
    let url = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + data.app_id +
        "&redirect_uri=" + data.redirect_uri + "&response_type=code&scope=snsapi_base&state=" + data.state + "#wechat_redirect";
    return url;
}




