//store中的index.js文件
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

const store = new Vuex.Store({
    //用来定义变量的，相当于vue中的data
    state:{
        baseURL: "http://org-active.test.vv-che.com", //"http://org-active.test.vv-che.com",
        app_id: "wxf8af826931365ee0",
        token: "",
        userInfo: null, //登录用户信息
        ayPerson: null, //登录对象信息
        objectPersonDetails: null, //登录对象人员详细信息
        selectObjectPersonDetails: null, //选中组织人对接工作人员详细信息
        selectMealsPersonId: null, //选中用餐人员Id
        selectMaterialPersonId: null, //选中物资领用人Id
        selectRecyclingPersonId: null, //选中物资交回人Id
        selectLivePersonId: null, //选中住宿人员Id
        materialStatus: null, //物资发放显示状态
        selectConsumptionCarId: null, //选中能耗车辆Id
        selectClearCarId: null, //选中清洁车辆Id
        serialNo: "", //活动编号
        phone: "", //登录用户手机号
        showOverlay: false, //是否显示遮罩层
        companyId: null, //公司Id
        activityId: null, //活动Id
        actObjId: null, //活动对象Id
        jobList: null, //活动岗位列表
    },
    //相当于computed
    getters:{
        getToken(state) {
            if (!state.token) {
                state.token = localStorage.getItem('token');
            }
            return state.token
        },
        getUserInfo(state) {
            if (!state.userInfo) {
                state.userInfo = JSON.parse(localStorage.getItem('userInfo'));
            }
            return state.userInfo
        },
        getAyPerson(state) {
            if (!state.ayPerson) {
                state.ayPerson = JSON.parse(localStorage.getItem('ayPerson'));
            }
            return state.ayPerson
        },
        getOPDetails(state) {
            if (!state.objectPersonDetails) {
                state.objectPersonDetails = JSON.parse(localStorage.getItem('objectPersonDetails'));
            }
            return state.objectPersonDetails
        },
        getActObjId(state) {
            if (!state.actObjId) {
                state.actObjId = localStorage.getItem('actObjId');
            }
            return state.actObjId
        },
        getCompanyId(state) {
            if (!state.companyId) {
                state.companyId = localStorage.getItem('companyId');
            }
            return state.companyId
        },
        getActivityId(state) {
            if (!state.activityId) {
                state.activityId = localStorage.getItem('activityId');
            }
            return state.activityId
        },
        getSerialNo(state) {
            if (!state.serialNo) {
                state.serialNo = localStorage.getItem('serialNo');
            }
            return state.serialNo
        },
        getUserPhone(state) {
            if (!state.phone) {
                state.phone = localStorage.getItem('phone');
            }
            return state.phone
        },
        getSelectOPDetails(state){
            if (!state.selectObjectPersonDetails) {
                state.selectObjectPersonDetails = JSON.parse(localStorage.getItem('selectObjectPersonDetails'));
            }
            return state.selectObjectPersonDetails
        },
        getSelectMealsPersonId(state){
            if (!state.selectMealsPersonId) {
                state.selectMealsPersonId = localStorage.getItem('selectMealsPersonId');
            }
            return state.selectMealsPersonId
        },
        getSelectMaterialPersonId(state){
            if (!state.selectMaterialPersonId) {
                state.selectMaterialPersonId = localStorage.getItem('selectMaterialPersonId');
            }
            return state.selectMaterialPersonId
        },
        getSelectRecyclingPersonId(state){
            if (!state.selectRecyclingPersonId) {
                state.selectRecyclingPersonId = localStorage.getItem('selectRecyclingPersonId');
            }
            return state.selectRecyclingPersonId
        },
        getSelectLivePersonId(state){
            if (!state.selectLivePersonId) {
                state.selectLivePersonId = localStorage.getItem('selectLivePersonId');
            }
            return state.selectLivePersonId
        },
        getMaterialStatus(state){
            if (!state.materialStatus) {
                state.materialStatus = sessionStorage.getItem('materialStatus');
            }
            return state.materialStatus
        },
        getSelectConsumptionCarId(state){
            if (!state.selectConsumptionCarId) {
                state.selectConsumptionCarId = localStorage.getItem('selectConsumptionCarId');
            }
            return state.selectConsumptionCarId
        },
        getSelectClearCarId(state){
            if (!state.selectClearCarId) {
                state.selectClearCarId = localStorage.getItem('selectClearCarId');
            }
            return state.selectClearCarId
        },
        getJobList(state){
            if (!state.jobList) {
                state.jobList = JSON.parse(localStorage.getItem('jobList'));
            }
            return state.jobList
        },
    },
    //这个比较特殊，这个里面的方法是用来同步提交到state中的。也就是说，当一个组件想要改变state中的值的话，必须通过mutations里面的方法才可以！
    mutations:{
        setToken(state,token){
            state.token = token;
            localStorage.setItem("token", token);
        },
        setUserInfo(state,userInfo){
            state.userInfo = userInfo;
            localStorage.setItem("userInfo", JSON.stringify(userInfo));
        },
        setAyPerson(state,ayPerson){
            state.ayPerson = ayPerson;
            localStorage.setItem("ayPerson", JSON.stringify(ayPerson));
        },
        setOPDetails(state,objectPersonDetails){
            state.objectPersonDetails = objectPersonDetails;
            localStorage.setItem("objectPersonDetails", JSON.stringify(objectPersonDetails));
        },
        setShowOverlay(state,showOverlay){
            state.showOverlay = showOverlay;
        },
        setActObjId(state,actObjId){
            state.actObjId = actObjId;
            localStorage.setItem("actObjId", actObjId);
        },
        setCompanyId(state,companyId){
            state.companyId = companyId;
            localStorage.setItem("companyId", companyId);
        },
        setActivityId(state,activityId){
            state.activityId = activityId;
            localStorage.setItem("activityId", activityId);
        },
        setSerialNo(state,serialNo){
            state.serialNo = serialNo;
            localStorage.setItem("serialNo", serialNo);
        },
        setUserPhone(state,phone){
            state.phone = phone;
            localStorage.setItem("phone", phone);
        },
        setSelectOPDetails(state,selectObjectPersonDetails){
            state.selectObjectPersonDetails = selectObjectPersonDetails;
            localStorage.setItem("selectObjectPersonDetails", JSON.stringify(selectObjectPersonDetails));
        },
        setSelectMealsPersonId(state,selectMealsPersonId){
            state.selectMealsPersonId = selectMealsPersonId;
            localStorage.setItem("selectMealsPersonId", selectMealsPersonId);
        },
        setSelectMaterialPersonId(state,selectMaterialPersonId){
            state.selectMaterialPersonId = selectMaterialPersonId;
            localStorage.setItem("selectMaterialPersonId", selectMaterialPersonId);
        },
        setSelectRecyclingPersonId(state,setSelectRecyclingPersonId){
            state.setSelectRecyclingPersonId = setSelectRecyclingPersonId;
            localStorage.setItem("setSelectRecyclingPersonId", setSelectRecyclingPersonId);
        },
        setSelectLivePersonId(state,selectLivePersonId){
            state.selectLivePersonId = selectLivePersonId;
            localStorage.setItem("selectLivePersonId", selectLivePersonId);
        },
        setMaterialStatus(state,materialStatus){
            state.materialStatus = materialStatus;
            sessionStorage.setItem("materialStatus", materialStatus);
        },
        setSelectConsumptionCarId(state,selectConsumptionCarId){
            state.selectConsumptionCarId = selectConsumptionCarId;
            localStorage.setItem("selectConsumptionCarId", selectConsumptionCarId);
        },
        setSelectClearCarId(state,selectClearCarId){
            state.selectClearCarId = selectClearCarId;
            localStorage.setItem("selectClearCarId", selectClearCarId);
        },
        setJobList(state,jobList){
            state.jobList = jobList;
            localStorage.setItem("jobList", JSON.stringify(jobList));
        }
    },
    actions: {

    }
})
export default store