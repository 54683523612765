import axios from 'axios';
import store from '@/assets/common/store'

//判断是否正式环境
function getIsProd() {
    let value = window.location.href;
    if (value !== null || value !== '') {
        //使用split 进行分割，一定要进行字符串判空
        let str = value.split("https://");
        if (str.length === 1) {
            str = value.split("http://");
        }
        const domain = str[1].split("/")[0];
        if (domain === 'org-active.vv-che.com') {
            return true
        } else {
            return false;
        }
    }
    return false;
}

//设置axios请求地址
axios.defaults.baseURL = getIsProd() ? 'https://apis.vv-che.com' : 'https://apis.test.vv-che.com/';

//设置请求超时
axios.defaults.headers.timeout = 3000;

//请求拦截器
axios.interceptors.request.use(res => {
    store.commit('setShowOverlay',true);
    res.headers.token = store.getters.getToken;
    return Promise.resolve(res);
}, function(err) {
    return Promise.reject(err);
});

//响应拦截器
axios.interceptors.response.use(res => {
    store.commit('setShowOverlay',false);
    return Promise.resolve(res);
}, function(err) {
    return Promise.reject(err);
});

export const post = (url, params) => {
    return axios.post(`${url}`, params).then(res => res.data);
}

export const get = (url, params) => {
    const getTimestamp = new Date().getTime();
    return axios.get(`${url}?timer=${getTimestamp}`, {
        params: params
    }).then(res => res.data);
}
